<template>
  <div class="sticky-top">
    <b-navbar toggleable="lg" type="dark" variant="secondary">
      <b-navbar-brand href="#">Deep Voice</b-navbar-brand>

      <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

      <b-collapse id="nav-collapse" is-nav>
        <b-navbar-nav>
          <b-nav-item @click="scrollToAnchor('what-is-deep-voice')">Deep Voiceとは</b-nav-item>
          <b-nav-item @click="scrollToAnchor('how-to-use')">どうやって使うの？</b-nav-item>
          <b-nav-item @click="scrollToAnchor('demonstration')">デモ</b-nav-item>
          <b-nav-item @click="scrollToAnchor('member')">開発メンバー</b-nav-item>
        </b-navbar-nav>
      </b-collapse>
    </b-navbar>
  </div>
</template>

<script>
export default {
  name: "PageHeader",
  methods: {
    scrollToAnchor: function (id) {
      const el = document.getElementById(id)
      // console.log(el)
      el.scrollIntoView({behavior: "smooth"})
    }
  }
}
</script>

<style scoped>

</style>