<template>
    <footer class="border-top bg-secondary text-light">
      <b-nav align="center pt-5">
<!--        <b-nav-item active>Active</b-nav-item>-->
<!--        <b-nav-item>Link</b-nav-item>-->
<!--        <b-nav-item>Another Link</b-nav-item>-->
<!--        <b-nav-item disabled>Disabled</b-nav-item>-->
      </b-nav>
      <p>&copy; 2021 Deep-Voice</p>
    </footer>
</template>

<script>
export default {
  name: "PageFooter"
}
</script>

<style scoped>

</style>