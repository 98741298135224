<template>
  <div>
    <div class="filter">
      <b-img :src="require('@/assets/mainIMG.jpg')" alt="" style="width: 100%; height: auto" fluid
             class="filter-img"></b-img>
        <div class="title">
          <h2 class="app-sub-title">声分解析アプリ</h2>
          <h1 class="app-name">Deep Voice</h1>
        </div>
    </div>
    <PageHeader></PageHeader>
    <div class="container">
      <div class="pt-5 pb-3 px-3">
        <h2 id="what-is-deep-voice">Deep Voiceとは</h2>
        <div class="row pt-3">
          <div class="col-sm-6">
            <b-img :src="require('@/assets/recording.png')" fluid style="width: 100%; height: auto"></b-img>
          </div>
          <div class="col-sm-6 d-flex flex-column align-items-start justify-content-center text-left">
            <p>あなたの声の特徴をAIが分析し，あなたの声にどんな有名人の声が含まれているのか判定します。</p>
            <p><a href="#demonstration">デモ</a>を楽しんでみてください。</p>
          </div>
        </div>
      </div>
      <div class="pt-5 pb-3 px-3 bg-light">
        <h2 id="how-to-use">どうやって使うの？</h2>
        <div class="row pt-3">
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-center text-left">
            <p>Deep VoiceはiPhone、androidのアプリとして開発が進められています。</p>
            <p>あなたに最高の体験を届けるためにエンジニアチームが寝る間も惜しんで開発を続けています。</p>
            <p>みなさまにDeep Voiceを使ってもらえる日を楽しみにしています。</p>
          </div>
          <div class="col-sm-6">
            <b-img :src="require('@/assets/develop_app.png')" fluid style="width: 100%; height: auto"></b-img>
          </div>
        </div>
      </div>
      <div class="pt-5 pb-3 px-3">
        <h2>アプリイメージ</h2>
        <div class="embed-responsive embed-responsive-16by9">
          <iframe class="embed-responsive-item" src="https://drive.google.com/file/d/1JY3-zxV5lzVVQ8SS5GV1s4rHUv_oSA6W/preview" allowfullscreen></iframe>
        </div>
      </div>
      <div class="pt-5 pb-3 px-3 bg-light">
        <h2 id="demonstration">デモンストレーション</h2>
        <div class="row pt-3 align-items-start">
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-center text-left">
            <p>「start」を押してあなたの声を録音してください。<br>
              録音が終わったら「stop」を押して録音を停止してください。<br>
              録音した声を判定するためには「判定」を押してください。
            </p>
            <div class="row mb-3">
              <div class="col">
                <b-form-select :options="genreOptions" v-model="genre"></b-form-select>
              </div>
              <div class="col">
                <b-form-select :options="sexOptions" v-model="sex"></b-form-select>
              </div>
            </div>
            <div class="row mb-5">
              <button type="button" @click="startRecord" :disabled="isRecording" class="btn btn-danger mr-3">start</button>
              <button type="button" @click="stopRecord" :disabled="!isRecording" class="btn btn-secondary mr-3">stop</button>
              <button type="button" @click="judge" :disabled="isRecording" class="btn btn-primary">判定</button>
            </div>
            <div v-show="isRecording" style="color: red">
              <p>【録音中】以下の文章をゆっくりと音読してください。</p>
              <p>「むかし、むかし、あるところに、おじいさんとおばあさんがありました。まいにち、おじいさんは山へしば刈りに、おばあさんは川へ洗濯に行きました。」</p>
              <p>録音が終わったら「stop」を押してください。</p>
            </div>
          </div>
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-center">
            <h4 v-if="isJudging">判定中</h4>
            <table v-else-if="results.length">
              <tr>
                <th>人物</th>
                <th>含有量（％）</th>
              </tr>
              <tr v-for="res in results" v-bind:key="res.name">
                <td>{{ res.name }}</td>
                <td>{{ Math.round(res.percent * 1000)/10 }}</td>
              </tr>
            </table>
            <h4 v-else>ここに結果が表示されます。</h4>
          </div>
        </div>
      </div>
      <div class="pt-5 pb-5 px-3">
        <h2 id="member">開発メンバー</h2>
        <div class="row pt-3">
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-start mb-3">
            <h4>原嶋春輝</h4>
            <p>データサイエンティスト</p>
            <p class="text-left px-3">
              データサイエンスのコンペティションで入賞経験あり。
              特にテーブルデータの解析，機械学習モデルの構築
              を強みとしており，今回は初めて音声分析に挑戦中！
            </p>
          </div>
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-start mb-3">
            <h4>井上隼英</h4>
            <p>アプリケーションエンジニア</p>
            <p class="text-left px-3">
              「deep-voice」で初めてのflutterに挑戦中。
            </p>
          </div>
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-start mb-3">
            <h4>徳永大空</h4>
            <p>データサイエンティスト</p>
          </div>
          <div class="col-sm-6 d-flex flex-column align-items-center justify-content-start mb-3">
            <h4>難波洸也</h4>
            <p>データサイエンティスト</p>
          </div>
        </div>
      </div>
    </div>
    <PageFooter></PageFooter>
  </div>
</template>

<script>
import PageHeader from "@/components/PageHeader.vue";
import PageFooter from "@/components/PageFooter.vue";

import axios from "axios";


export default {
  name: 'Home',
  components: {PageHeader, PageFooter},
  data: function () {
    return {
      stream: null,
      mediaRecorder: null,
      chunk: [],
      blob: null,
      isRecording: false,
      results: [],
      genreOptions: [
        {value: 'artist', text: '歌手'},
        {value: 'voice_actor', text: '声優'},
      ],
      genre: 'artist',
      sexOptions: [
        {value: 'male', text: '男性'},
        {value: 'female', text: '女性'},
      ],
      sex: 'male',
      isJudging: false
    }
  },
  methods: {
    startRecord() {
      // マイクのアクセス許可
      if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
        console.log('getUserMedia supported')
        navigator.mediaDevices.getUserMedia(
            {
              audio: true
            }
        ).then(stream => {
          this.stream = stream
          this.mediaRecorder = new MediaRecorder(stream)
          this.mediaRecorder.ondataavailable = e => this.chunk.push(e.data)
          this.mediaRecorder.onstop = () => {
            console.log('recorder stopped')
            // console.log(this.chunk)
            this.blob = new Blob(this.chunk, {type: 'audio/ogg; codecs=opus'})
          }
          this.chunk = []
          this.mediaRecorder.start()
          this.isRecording = true
          console.log(this.mediaRecorder.state)
          console.log('recorder started')
        }).catch(e => console.log(e))
      } else {
        alert('getUserMedia is not supported on your browser!')
      }
    },
    stopRecord() {
      this.mediaRecorder.stop()
      this.isRecording = false
      console.log(this.mediaRecorder.state)
      console.log('recorder stopped')
    },
    judge() {
      if (this.chunk.length === 0) {
        alert('音声を録音してください。')
        return
      }
      this.isJudging = true
      const data = new FormData()
      data.append('file', this.chunk[0], 'voice.webm')
      const self = this
      const url = `https://api.deep-voice.net/judge/${this.genre}/${this.sex}`
      axios.post(url, data, {
        headers: {'content-type': 'multipart/form-data'}
      }).then(res => {
        console.log('success')
        // console.log(res.data)
        self.results = res.data.result
        this.isJudging = false
      }).catch((e) => {
        // console.error(e.response.status)
        this.isJudging = false
        self.results = []
        if (e.response.status === 400) {
          alert(`もう一度音声を録音してください。（${e.response.data.detail}）`)
        } else {
          alert('予期せぬエラーが発生しました。時間をおいてもう一度お試しください。')
        }
      })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}

ul {
  list-style-type: none;
  padding: 0;
}

li {
  display: inline-block;
  margin: 0 10px;
}

a {
  color: #42b983;
}

.filter {
  background-color: #000;
  position: relative;
}

.filter-img {
  display: block;
  opacity: .5;
}

.title {
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%,-50%);
  -webkit-transform: translate(-50%,-50%);
  transform: translate(-50%,-50%);
  margin:0;
  padding:0;
  color: white;
  width: 100%;
}

.app-name {
  font-size: 96px;
  font-weight: bold
}

.app-sub-title {
  font-size: 48px;
}

@media (max-width: 576px) {
  .app-name {
    font-size: 48px;
  }
  .app-sub-title {
    font-size: 24px;
  }
}

</style>
